<template>
  <div id="app" @click="resetTimer()" @touchstart="resetTimer()">
    <my-header></my-header>
    <router-view />

    <section class="copyright"></section>
  </div>
</template>

<script>
import Header from "./components/Header";

export default {
  name: "App",
  components: {
    "my-header": Header
  },
  data() {
    return {
      timeout: 0,
      showCover: false,
      refreshing: false,
      registration: null,
      updateAvailable: false
    };
  },
  created() {
    this.startTimer();
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) return;

        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showUIUpdate(e) {
      this.registration = e.detail;
      this.refreshApp();
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    resetTimer() {
      let vm = this;
      window.clearTimeout(vm.timeout);
      vm.showCover = false;
      this.startTimer();
    },
    startTimer() {
      window.onload = this.resetTimer;
      window.onmousemove = this.resetTimer;
      window.onmousedown = this.resetTimer; // catches touchscreen  as well
      window.ontouchstart = this.resetTimer; // catches touchscreen swipes as well
      window.onclick = this.resetTimer; // catches touchpad clicks as well
      window.onkeydown = this.resetTimer;
      clearTimeout(this.timeout);
      this.timeout = 0;
      let vm = this;
      this.timeout = window.setTimeout(function() {
        setTimeout(function() {
          vm.showCover = true;
        }, 500);
      }, 180000);
    }
  }
};
</script>

<style src="../public/css/main.css"></style>
