<template>
  <section class="main">
    <div class="banner-bg">
      <div class="banner">
        <!-- <img
        src="https://uploads-ssl.webflow.com/5f2d95cb45872fc361ffb5a6/5f400f06eca59a7dc356158f_BackgroundShapes.svg"
        loading="lazy"
        alt=""
        class="shapes-bg"
        style="will-change: transform; transform: translate3d(0px, -24.9612%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
      /> -->
        <h1>katrina lalata</h1>
      </div>
      <div class="sub-head"><p>ux ui designer strategist</p></div>
      <img style="width: 60px;" src="@/assets/img/arrow-down.png" />
    </div>
    <div class="work">
      <div class="intro">
        <h3>
          Digital designer of things and experiences
        </h3>

        <div class="kit">
          <h2>SKILLS</h2>
          <p>Visual Design</p>
          <p>
            UX/UI Design
          </p>
          <p>Project Management</p>
          <p>User Research & Testing</p>
          <p>Brand Strategy & Identity</p>
          <p>Information Architecture</p>
          <p>Personas & User Stories</p>
          <p>Wireframing & Prototyping</p>
        </div>

        <div class="kit">
          <h2>TOOLS</h2>
          <p>Figma</p>
          <p>
            Usability Hub
          </p>
          <p>HTML, CSS, & JavaScript</p>
          <p>GitHub</p>
          <p>Bitbucket</p>
          <p>Vue.js</p>
          <p>Wordpresss</p>
          <p>After Effects</p>
          <p>Photoshop</p>
        </div>
      </div>

      <div class="contain">
        <button class="animated-box in" @click="popup = 1">
          <img class="project" src="@/assets/img/henry.png" />
        </button>
        <button class="animated-box in" @click="popup = 2">
          <img class="project" src="@/assets/img/iybi.png" />
        </button>
        <button class="animated-box in" @click="popup = 3">
          <img class="project" src="@/assets/img/ptpa.png" />
        </button>
        <button class="animated-box in" @click="popup = 4">
          <img class="project" src="@/assets/img/allied.png" />
        </button>
        <button class="animated-box in" @click="popup = 5">
          <img class="project" src="@/assets/img/popped.png" />
        </button>
        <button class="animated-box in" @click="popup = 6">
          <img class="project" src="@/assets/img/mgc.png" />
        </button>
        <button class="animated-box in" @click="popup = 7">
          <img class="project" src="@/assets/img/poptix.png" />
        </button>
        <button class="animated-box in" @click="popup = 8">
          <img class="project" src="@/assets/img/zest.png" />
        </button>

        <div class="animated-box in">
          <img class="project" src="@/assets/img/tradeshow.png" />
        </div>

        <!-- <button
          class="border-gradient border-gradient-purple"
          @click="popup = 1"
        >
          <img class="project" src="@/assets/img/tradeshow.png" />
        </button> -->
      </div>
    </div>
    <div v-if="popup == 2" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>I.Y.B.I Barber Studio</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <img class="project-site" src="@/assets/img/iybi-click.png" />
      </div>
    </div>
    <div v-if="popup == 3" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>PTPA</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <iframe src="https://ptpaplayers.com/"></iframe>
      </div>
    </div>
    <div v-if="popup == 7" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>POPTIX</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <iframe src="https://poptix.com/"></iframe>
      </div>
    </div>
    <div v-if="popup == 5" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>POPPED</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <img class="project-site" src="@/assets/img/popped-click.png" />
      </div>
    </div>
    <div v-if="popup == 4" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>Allied Physicians Group</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <img class="project-site" src="@/assets/img/allied-click.png" />
      </div>
    </div>

    <div v-if="popup == 1" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>Henry the Dentist</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <div class="app-contain">
          <img class="project-app" src="@/assets/img/henry1.png" />
          <img class="project-app" src="@/assets/img/henry2.png" />
          <img class="project-app" src="@/assets/img/henry3.png" />
          <img class="project-app" src="@/assets/img/henry4.png" />
          <img class="project-app" src="@/assets/img/henry5.png" />
        </div>
      </div>
    </div>

    <div v-if="popup == 8" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>Zest</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <div class="app-contain">
          <img class="project-app" src="@/assets/img/zest1.png" />
          <img class="project-app" src="@/assets/img/zest2.png" />
          <!-- <img class="project-app" src="@/assets/img/zest3.png" /> -->
          <img class="project-app" src="@/assets/img/zest4.png" />
          <img class="project-app" src="@/assets/img/zest5.png" />
          <!-- <img class="project-app" src="@/assets/img/zest6.png" /> -->
        </div>
      </div>
    </div>

    <div v-if="popup == 6" class="popup">
      <div class="offclick" @click="popup = false"></div>
      <div class="project-container">
        <h1>Mouth Guard Club</h1>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> -->
        <div class="app-contain">
          <img class="project-app" src="@/assets/img/mgc1.png" />
          <img class="project-app" src="@/assets/img/mgc2.png" />
          <img class="project-app" src="@/assets/img/mgc3.png" />
          <img class="project-app" src="@/assets/img/mgc4.png" />
          <img class="project-app" src="@/assets/img/mgc5.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      current: "overview",
      popup: null
    };
  },
  methods: {
    toggleActive(event) {
      this.current = event.target.id;
    }
  }
};
</script>

<style>
.nav-container {
  display: none;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-bg {
  width: 100vw;
  height: 40vh;
  /* position: absolute; */
  top: 0;
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23202020' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
}
.banner {
  -webkit-background-clip: text;
  background-image: linear-gradient(
    to right,
    #6eff94,
    #4de5ff,
    #3547ff,
    #b24fff,
    #ffa6c3
  );
  -webkit-text-fill-color: transparent;
  color: #464646;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  align-items: center;
}
.work {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  margin: 2% 0;
}
.contain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 60%;
}
button {
  background: none;
  text-decoration: inherit;
  font-family: system-ui;
  font-size: 1rem;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.offclick {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}
img.project {
  padding: 0;
  width: 300px;
  transition: all 0.3s;
  display: block;
  height: auto;
  transform: scale(1);
}
img.project:hover {
  transform: scale(1.1);
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  margin: 1%;
}
.border-gradient-purple {
  border-image-source: linear-gradient(
    to left,
    #6eff94,
    #4de5ff,
    #3547ff,
    #b24fff,
    #ffa6c3
  );
}

.project-container {
  background: #060606;
  color: white;
  z-index: 10;
  width: 80%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 10px solid #78f79a40; */
}

.project-container h1 {
  -webkit-background-clip: text;
  background-image: linear-gradient(
    to right,
    #6eff94,
    #4de5ff,
    #3547ff,
    #b24fff,
    #ffa6c3
  );
  -webkit-text-fill-color: transparent;
}

.animated-box {
  position: relative;
  border: none;
  margin: 2%;
  overflow: hidden;
}

.animated-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border-radius: 4px; */
  background: linear-gradient(
    120deg,
    #6eff94,
    #4de5ff,
    #3547ff,
    #b24fff,
    #ffa6c3
  );
  background-size: 300% 300%;
  clip-path: polygon(
    0% 100%,
    5px 100%,
    5px 5px,
    calc(100% - 5px) 5px,
    calc(100% - 5px) calc(100% - 5px),
    5px calc(100% - 5px),
    5px 100%,
    100% 100%,
    100% 0%,
    0% 0%
  );
}

.animated-box.in:after {
  animation: frame-enter 1s forwards ease-in-out reverse,
    gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(
      0% 100%,
      5px 100%,
      5px 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) calc(100% - 5px),
      5px calc(100% - 5px),
      5px 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  25% {
    clip-path: polygon(
      0% 100%,
      5px 100%,
      5px 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) calc(100% - 5px),
      calc(100% - 5px) calc(100% - 5px),
      calc(100% - 5px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  50% {
    clip-path: polygon(
      0% 100%,
      5px 100%,
      5px 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) 5px,
      calc(100% - 5px) 5px,
      100% 0%,
      0% 0%
    );
  }
  75% {
    -webkit-clip-path: polygon(
      0% 100%,
      5px 100%,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 0%,
      0% 0%
    );
  }
  100% {
    -webkit-clip-path: polygon(
      0% 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      5px 100%,
      0% 100%
    );
  }
}

iframe {
  height: 647px;
  width: 1024px;
}
.project-site {
  height: 700px;
}
.app-contain {
  margin-top: 2%;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.project-app {
  height: 550px;
  flex-wrap: wrap;
}

.kit h2 {
  color: #6eff94;
  text-align: left;
  margin: 0;
  font-size: 40px;
  font-family: "HKGrotesk-Black";
}

.kit p {
  font-family: HKGrotesk-Medium;
  font-size: 18px;
  color: white;
  margin: 0;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.kit {
  margin-top: 10%;
}
.intro {
  width: 20%;
}
.intro h3 {
  font-family: "HKGrotesk-Medium";
  font-size: 34px;
  text-align: center;
  letter-spacing: 10px;
  margin-bottom: 20px;
  margin-left: 0;
  text-transform: uppercase;
  color: white;
  text-align: left;
}
.sub-head p {
  font-family: "HKGrotesk-Black";
  font-size: 24px;
  color: rgb(145 145 145 / 50%);
  text-transform: uppercase;
  margin-top: 0;
  letter-spacing: 3px;
  /* font-family: black; */
}
</style>
